import React from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row
} from "reactstrap";
import axios from "axios";
import Alerter from "../Alert/Alert";

import "./Form.scss";

export default class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      error: false,
      submit: false
    };
    setTimeout(() => {
      this.setState({ submit: !this.state.submit });
    }, 3000);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  throwError = () => {
    this.setState({
      error: !this.state.error
    });
  };

  getFormData = object => {
    let formData = new FormData()

    Object.keys(object).forEach(key => {
      formData.append(key, object[key])
    })

    return formData
  }

  handleSubmit = (event, values, submit) => {
    if (this.state.submit === true) {
      event.preventDefault();
      let uri = process.env.GATSBY_FORM_HANDLER_API_URL;

      let formData = this.getFormData(values);

      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Api-Key": process.env.GATSBY_FORM_HANDLER_API_KEY
        }
      };

      axios
        .post(uri, formData, axiosConfig)
        .then(response => {
          if (response.status === 200) {
            this.setState({
              error: false
            });
            this.toggle();
            this.form && this.form.reset();
          } else {
            this.throwError();
          }
        })
        .catch(() => {
          this.throwError();
        });
    } else {
      return null;
    }
  };

  render() {
    return (
      <div className="px-2 px-md-12 py-4">
        <AvForm
          className="pt-2 px-2 px-md-12"
          onValidSubmit={this.handleSubmit}
          ref={c => (this.form = c)}
          encType="multipart/form-data"
        >
          <Row>
            <Col md={12}>
              <AvField
                name="firstName"
                label="First Name"
                labelClass="required"
                errorMessage="Please enter your first name"
                type="text"
                required
              />
            </Col>
            <Col className="d-none">
              <AvField name="_age" label="Age" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AvField
                name="lastName"
                label="Last Name"
                labelClass="required"
                errorMessage="Please enter your last name"
                type="text"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AvField
                name="businessName"
                label="Business Name"
                labelClass=""
                errorMessage="Please enter your business name"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AvField
                name="phoneNumber"
                label="Phone Number"
                labelClass=""
                errorMessage="Please enter your phone number"
                type="text"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AvField
                name="email"
                label="E-mail Address"
                labelClass="required"
                type="email"
                placeholder="email@domain.com"
                validate={{
                  required: {
                    value: true,
                    errorMessage:
                      "The email should follow the proper format, Ex. 'email@domain.com'"
                  },
                  email: {
                    value: true,
                    errorMessage:
                      "The email should follow the proper format, Ex. 'email@domain.com'"
                  }
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AvField
                name="comments"
                label="Comments"
                labelClass="required"
                errorMessage="Please enter your comments"
                type="textarea"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col align="center" md={12}>
              <Button className="mt-3 px-4" color="info">
                Submit
              </Button>
            </Col>
          </Row>
        </AvForm>

        <Alerter
          alertColor="danger"
          isOpen={this.state.error}
          toggle={this.throwError}
          text="There was an issue sending your message. Please try again."
        />

        <Modal
          contentClassName="p-2 text-secondary"
          centered
          isOpen={this.state.modal}
        >
          <ModalHeader className="text-center" tag="h4" toggle={this.toggle}>
            Thank you!
          </ModalHeader>
          <ModalBody>Your message has been sent to WBA Labs. You should expect an answer to your question within 5 business days.</ModalBody>
        </Modal>
      </div>
    );
  }
}
