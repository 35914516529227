import React from "react";
import Layout from "../components/Layout";
import RightColumn from "../components/RightColumn";
import ContactForm from "../components/Form/Form";

export default class ContactUsPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo22.jpg" sidebarImageAlt="" metaTitle="Contact Us">
        <div id="mainContent">
          <div id="panel">
            <div id="content_0_body_0_pnlContactForm">
              <div class="header-one">Contact Us</div>
              <p>
                For new accounts, general questions, or shipping samples:
                <br />
                <strong>WBA Analytical Laboratories</strong>
                <br />
                3609 Johnson Road
                <br />
                Springdale, AR 72762
                <br />
                (479) 290-5062{" "}
              </p>
              <p>
                Please submit the&nbsp;
                <a href="/documents/wba-arf2.pdf" target="_blank" rel="noopener noreferrer">
                  Account Request Form
                </a>
                &nbsp;for new accounts, and someone will be in contact with you
                soon.
              </p>
              <br />
              <div class="header-one">Contact Us Form</div>
              <ContactForm />

              <br class="clearfloat" />
              <div>
                <div class="header-one">Springdale, AR</div>
                <p>
                  (479) 290-5063 - Chemistry Services
                  <br />
                  (479) 290-5064 - Microbiology Services
                  <br />
                  (479) 290-5064 - Research Services
                  <br />
                  (479) 290-5064 - Consulting Services
                </p>
              </div>
            </div>
          </div>
        </div>

        <RightColumn />
      </Layout>
    );
  }
}
