import React from "react";
import { Alert } from "reactstrap";

import "./alert.scss";

class Alerter extends React.Component {
  render() {
    return (
      <Alert
        isOpen={this.props.isOpen}
        className={`d-flex py-2 mt-3 px-3 bg-${
          this.props.alertColor ? this.props.alertColor : "secondary"
        } text-${
          this.props.textColor ? this.props.textColor : "white"
        } justify-content-center align-items-center`}
        aria-disabled="true"
        color="success"
      >
        <span>{this.props.text}</span>
        <button
          type="button"
          onClick={this.props.toggle}
          className="close ml-3"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">X</span>
        </button>
      </Alert>
    );
  }
}

export default Alerter;
